import React, { Component } from 'react';
import './App.css';


class RandomNumberGenerator extends Component {
    constructor(){
        super()

        this.state = {
            random_number: 0,
            upper_limit:0,
            button_pressed:0
        }

        this.generate = this.generate.bind(this);
        this.updateUpperLimit = this.updateUpperLimit.bind(this);
    }

    generate(){
        let number = Math.ceil(Math.random() * this.state.upper_limit);
        this.setState({
            random_number: number,
            button_pressed: this.state.button_pressed+1
        });
    }

    updateUpperLimit(event){
        this.setState({
            upper_limit: event.target.value
        });
    }

  render() {
    return (
      <div className="random-number-generator">
        <h2>Random Number Generator!</h2>
        <div>
            <strong>Set upper limit:</strong>
            <input type="number" value={this.state.upper_limit} onChange={this.updateUpperLimit}/>
        </div>
        <input type="number" readOnly value={this.state.random_number}/>
        <button onClick={this.generate}>Generate</button>
        {this.state.button_pressed > 50 ? <div>You really want that lucky number!! Keep it up!!</div>: ""}
      </div>
    );
  }
}

export default RandomNumberGenerator;
